import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5390784b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "after-as-rhombus" }
const _hoisted_3 = { class: "a" }
const _hoisted_4 = { class: "kanban-items-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoadingSpinner = _resolveComponent("LoadingSpinner")!
  const _component_Badge = _resolveComponent("Badge")!
  const _component_KanbanItem = _resolveComponent("KanbanItem")!
  const _component_Draggable = _resolveComponent("Draggable")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_LoadingSpinner, { loading: _ctx.loading }, null, 8, ["loading"]),
    (!_ctx.loading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("ul", _hoisted_2, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.lists, (value) => {
              return (_openBlock(), _createElementBlock("li", {
                key: value.status
              }, [
                _createElementVNode("span", _hoisted_3, [
                  _createTextVNode(_toDisplayString(value.status) + " ", 1),
                  _createVNode(_component_Badge, { class: "board-header-badge" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(value.items.length), 1)
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _createElementVNode("div", _hoisted_4, [
                  _createVNode(_component_Draggable, {
                    list: value.items,
                    group: "lists",
                    "item-key": "id",
                    onStart: _cache[0] || (_cache[0] = ($event: any) => (_ctx.dragging = true)),
                    onEnd: _cache[1] || (_cache[1] = ($event: any) => (_ctx.dragging = false)),
                    animation: 200,
                    "ghost-class": "ghost-card",
                    onChange: ($event: any) => (_ctx.onItemChanged(value, $event))
                  }, {
                    item: _withCtx(({ element }) => [
                      _createVNode(_component_KanbanItem, {
                        userId: _ctx.getUserId,
                        section: _ctx.section,
                        data: element,
                        onOnClickEdit: _ctx.onClickEdit
                      }, null, 8, ["userId", "section", "data", "onOnClickEdit"])
                    ]),
                    _: 2
                  }, 1032, ["list", "onChange"])
                ])
              ]))
            }), 128))
          ])
        ]))
      : _createCommentVNode("", true)
  ], 64))
}