
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    data: Object as any,
    section: String,
    userId: String,
  },
  emits: ["onClickEdit"],
  computed: {
    cardData(): any {
      switch (this.section) {
        case "opps":
          return {
            custName: this.data.cust_name,
            amount:
              this.data.amount &&
              parseInt(this.data.amount, 10).toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              }),
            date: this.formatStringDate(this.data.est_close),
            id: this.data.id,
            assignee: this.data.assigned_to && this.data.assigned_to,
            assigneeText: "Assigned to: ",
          };
        case "quotes":
          return {
            custName: this.data.name,
            amount:
              this.data.quote_amount &&
              parseInt(this.data.quote_amount, 10).toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              }),
            date: this.formatStringDate(this.data.valid_thru),
            id: this.data.id,
            assignee: this.data.quoted_by && this.data.quoted_by,
            assigneeText: "Quoted by: ",
          };
        case "orders":
          return {
            custName: this.data.cust_name,
            amount:
              this.data.order_total_sum &&
              parseInt(this.data.order_total_sum, 10).toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              }),
            date: this.formatStringDate(this.data.date),
            id: this.data.so_id,
            assignee:
              this.data.rep_items &&
              `Reps: ${this.data.rep_items
                .map((item: any) => item.rep_name)
                .join(", ")}`,
            assigneeText: "",
          };
        case "fieldservices":
          return {
            id: this.data.order_no,
            custName: this.data.cust_name,
            date: this.formatStringDate(this.data.service_date),
            assignee: this.data.serviced_by,
          };
        case "productionWorkOrder":
          return {
            id: this.data.wONumber,
            custName: this.data.description,
            date: this.formatStringDate(this.data.confirmShipDate),
            assignee: this.data.workCenterType,
            amount: this.data.quantity,
            assigneeText: "Work Center Type: ",
          };
        default:
          return {};
      }
    },
  },
  methods: {
    formatStringDate(dueDate: string) {
      if (!dueDate || dueDate == null) return "";
      const reformatedDueDate = new Date(dueDate)
        ?.toLocaleString("en-US", {
          day: "2-digit",
          month: "2-digit",
          year: "2-digit",
        })
        .split("/")
        .join("-");

      return reformatedDueDate;
    },
    onClickEdit() {
      this.$emit("onClickEdit", this.data);
    },
  },
});
