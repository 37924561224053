
import { defineComponent } from "vue";
import SalesOppsDataTableWrapper from "@/components/Sales/SalesOppsDataWrapper.vue";
import Card from "primevue/card";
import Button from "primevue/button";
import SaleOpp from "@/types/saleOpps";
import Footer from "@/components/Footer.vue";
import { mapActions, mapGetters } from "vuex";
export default defineComponent({
  name: "SalesOpps",
  components: {
    Button,
    SalesOppsDataTableWrapper,
    Card,
    Footer,
  },
  computed: {
    ...mapGetters({
      getSaleOpps: "saleOpps/getSaleOpps",
      getRangeEnd: "saleOpps/getRangeEnd",
      getCurrPage: "saleOpps/getCurrPage",
      getFirstRow: "saleOpps/getFirstRow",
      getCustomer: "customerInquiry/getCustomer",
      getSalesOppsIds: "customerInquiry/getSalesOppsIds",
      getNewOppsCount: "salesInquiry/getNewOppsCount",
    }),
    getCustomerFields(): any {
      const { cust_id, name, contact_id_items } = this.getCustomer(this.index);
      return {
        cust_id,
        name,
        contact_id_items,
      };
    },
  },
  props: {
    index: Number,
    currentView: String,
  },
  mounted() {
    this.first = this.getFirstRow;
  },

  created() {
    this.$emit(
      "update-badge",
      "saleopp_ids",
      this.getSalesOppsIds(this.index)
        ? this.getSalesOppsIds(this.index).length
        : 0,
    );
  },

  updated() {
    this.$emit(
      "update-badge",
      "saleopp_ids",
      this.getSalesOppsIds(this.index)
        ? this.getSalesOppsIds(this.index).length
        : 0,
    );
  },

  data() {
    return {
      items: [] as Array<SaleOpp>,
      saleOpp: null as unknown,
      selectedRow: null,
      first: 0,
      rows: 10,
      page: 1,
      order: false,
      orderRecordsBy: "",
      sortBy: "",
      sortOrder: 1,
      sortField: "",
      isLoadingSalesOpps: false,
      showDialog: false as boolean,
      isEditing: false as boolean,
      id: "",
    };
  },
  methods: {
    ...mapActions({
      setFirstRow: "saleOpps/setFirstRow",
      pushSaleOppsId: "customerInquiry/pushSaleOppsId",
      addOpenedSalesOrder: "salesInquiry/addOpenedSalesInquiry",
    }),
    printExportData() {
      (this.$refs as any).salesOppsTable.printTable();
    },
    clearFilters() {
      (this.$refs as any).salesOppsTable.clearAllFiltersAndFetch();
    },
    fetchSaleOpps(opp: any) {
      if (this.showDialog == true) {
        this.showDialog = false;
      }
      this.isLoadingSalesOpps = true;
      this.pushSaleOppsId({
        index: this.index,
        id: opp.id,
      });
      (this.$refs.salesOppsTable as any).fetchSaleOpps(false);
    },
    handleSalesOpp(editing: boolean, data = null as any) {
      const customer = this.getCustomer(this.index);
      const cust_id = customer.cust_id;
      if (editing && data) {
        data = {
          oldRecord: JSON.parse(JSON.stringify(data)),
          saleType: "opportunities",
          ...data,
        };

        this.addOpenedSalesOrder(data);

        this.$router.push(
          `/customers/${cust_id}/sales/opportunities/${data.id}`,
        );
      } else {
        const count =
          this.getNewOppsCount === undefined ? 1 : this.getNewOppsCount + 1;
        const id = `New Opp-${count}`;
        const itemToEdit = {
          id: id,
          saleType: "opportunities",
          oldRecord: {},
        };

        this.$router.push(`/customers/${cust_id}/sales/opportunities/${id}`);
      }
    },
    hideDialog(hidden: boolean) {
      this.showDialog = hidden;
      this.saleOpp = null;
      this.isEditing = false;
    },
  },
});
