
import SaleOpp, { AttachmentItem, SalesOrderItem } from "@/types/saleOpps";
import { mapActions, mapGetters, mapState } from "vuex";
import Quote from "@/types/quote";
import Dialog from "primevue/dialog";
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import TextArea from "primevue/textarea";
import Dropdown from "primevue/dropdown";
import Calendar from "primevue/calendar";
import Search from "@/components/Search.vue";
import InputNumber from "primevue/inputnumber";
import { defineComponent } from "vue";
import AttachmentRecords from "@/components/Attachments/AttachmentRecords.vue";
import SoQuoteService from "@/services/SOQuotesService";
import SaleOppsService from "@/services/SaleOppsService";
import CustomerService from "@/services/CustomerService";
import ProspectService from "@/services/ProspectService";
import Customer from "@/types/customer";
import Prospect from "@/types/prospect";
import useVuelidate from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";
import SOQuote from "@/types/soquote";
import Response from "@/types/response/response";
import ContactAutocomplete from "@/components/Autocompletes/Contact.vue";
import Utils from "@/utility/utils";
export default defineComponent({
  components: {
    Dialog,
    InputText,
    Button,
    TextArea,
    Dropdown,
    Calendar,
    Search,
    InputNumber,
    AttachmentRecords,
    ContactAutocomplete,
  },
  props: {
    opportunity: Object,
    customer: Object,
    isEditing: Boolean,
    show: Boolean,
    opportunity_id: String,
  },
  emits: ["hide", "onSave"],
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  validations() {
    return {
      customerName: {
        required: helpers.withMessage("Please Select a Customer", required),
      },
    };
  },
  data() {
    return {
      saleOpp: {} as SaleOpp,
      saleOppCopy: {} as SaleOpp,
      soQuote: {} as SOQuote,
      loading: false,
      isLoadingQuotes: false,
      isFormSubmitted: false,
      hidden: true,
      quote: {
        quote: "",
      } as Quote,
      salesOrder: {
        so: "",
      } as SalesOrderItem,
      loadingControls: false,
      customerName: "",
      prospectName: "",
      soQuoteService: new SoQuoteService(process.env.VUE_APP_ABSTRACTION_API),
      saleOppService: new SaleOppsService(process.env.VUE_APP_ABSTRACTION_API),
      customerSevice: new CustomerService(),
      prospectService: new ProspectService(process.env.VUE_APP_ABSTRACTION_API),
      saleOppAmount: 0.0,
      submitLoading: false,
      loadingAttachments: false,
    };
  },
  updated() {
    if (this.opportunity != null) {
      this.saleOpp = JSON.parse(JSON.stringify(this.opportunity as SaleOpp));
      this.saleOppCopy = JSON.parse(
        JSON.stringify(this.opportunity as SaleOpp),
      );
      this.saleOppAmount = parseFloat(this.opportunity.amount);
      this.initCust();
      this.initProspect();
      this.getControls();
    } else {
      this.initSalesOpp();
      if (this.customer) {
        this.saleOpp.cust = this.customer.cust_id;
        this.initCust();
      }
    }
  },
  created() {
    this.hidden = this.show;
    this.initSalesOpp();
    if (this.isSaleOpportunityControlEmpty) {
      this.loadingControls = true;
      this.fetchControl({
        procedure: "SALEOPP.CONTROL",
        filename: "CONTROL",
        id: "SALEOPP",
      }).finally(() => {
        this.loadingControls = false;
      });
    }
  },
  computed: {
    ...mapState(["control"]),
    ...mapGetters({
      isSaleOpportunityControlEmpty: "control/isSaleOpportunityControlEmpty",
      getUserId: "session/getUserId",
      getFolderNames: "control/getFolderNames",
      getClient: "session/getClient",
      getDefaultCodeItem: "coControl/getDefaultCodeItem",
    }),
    header(): string {
      //return this.isEditing ? `Opportunity #${ this.saleOpp.id ?? 0 }` : "New Sales Opportunity";
      return this.isEditing
        ? `Opportunity #${this.opportunity_id}`
        : "New Sales Opportunity";
    },
  },
  methods: {
    ...mapActions({
      fetchControl: "control/fetchControl",
      addNotification: "notification/add",
      fetchAttachments: "attachments/fetchAttachments",
      downloadAttachment: "attachments/downloadAttachment",
      postAttachment: "attachments/postAttachment",
      deleteAttachment: "attachments/deleteAttachment",
    }),
    handleDeleted(event: any) {
      if (event.success)
        this.fetchOpportunityAttachments(this.saleOpp, "RE-FETCH");
    },
    handleSaved(event: any) {
      if (event.success)
        this.fetchOpportunityAttachments(this.saleOpp, "RE-FETCH");
    },
    fetchOpportunityAttachments(object: SaleOpp, status: string) {
      switch (status) {
        case "FETCH":
          {
            this.loadingAttachments = true;
            this.fetchAttachments({
              record: object,
            }).finally(() => {
              this.loadingAttachments = false;
            });
          }
          break;
        case "RE-FETCH":
          // fetch updated SOQuote
          this.saleOppService
            .getSaleOpps(
              this.saleOpp.id,
              1,
              100,
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "cust_name",
            )
            .then((response) => {
              this.saleOpp =
                (response as any).saleopp_items[0] ?? this.opportunity;
              this.saleOppCopy = JSON.parse(JSON.stringify(this.saleOpp));

              this.loadingAttachments = true;
              this.fetchAttachments({
                record: this.saleOpp,
              }).finally(() => {
                this.loadingAttachments = false;
              });
            });
          break;
        default:
          break;
      }
    },
    async createQuote() {
      this.isFormSubmitted = true;
      // ensure that the sale opp fields are filled correctly
      const isCustomerFieldPopulated = await this.v$.customerName.$validate();
      if (isCustomerFieldPopulated) {
        // MAP QUOTE TO SALE OPP
        this.soQuote.id = "";
        this.soQuote.date = Utils.formatDate(new Date());
        this.soQuote.prospect = this.saleOpp.prospect || "";
        this.soQuote.customer = this.saleOpp.cust;
        this.soQuote.name = this.saleOpp.cust_name || "";
        this.soQuote.co_code = this.getDefaultCodeItem;

        this.isFormSubmitted = true;
        this.isLoadingQuotes = true;

        this.soQuoteService
          .postSOQuote(this.soQuote)
          .then((response) => {
            let soQuoteId = (response as any).recordId;

            if (this.saleOpp.quote_items == null) {
              this.saleOpp.quote_items = [];
            }

            this.saleOpp.quote_items.push({ quote: soQuoteId });

            this.addNotification({
              message: `Created Sales Quote: ${soQuoteId}`,
              type: "success",
            });
          })
          .finally(() => {
            this.submitLoading = false;
            this.isFormSubmitted = false;
            this.isLoadingQuotes = false;
          });
      }
    },
    getControls() {
      if (this.isSaleOpportunityControlEmpty) {
        this.loadingControls = true;
        this.fetchControl({
          procedure: "SALEOPP.CONTROL",
          filename: "CONTROL",
          id: "SALEOPP",
          control: "SaleOpp",
        }).finally(() => {
          this.loadingControls = false;
        });
      }
    },
    initSalesOpp() {
      this.saleOpp = {
        contact: "",
        amount: "",
        type: "",
        source: "",
        est_close: "",
        date: "",
        priority: "",
        assigned_to: this.getUserId,
        follow_up_date: "",
        status: "",
        notes: "",
        quote_items: [] as Array<Quote>,
        so_items: [] as Array<SalesOrderItem>,
        prospect: "",
        cust: "",
        attachments_items: [] as Array<AttachmentItem>,
      } as SaleOpp;
      this.saleOppAmount = 0.0;
      this.customerName = "";
      this.prospectName = "";
      this.isFormSubmitted = false;
    },
    initSoQuote() {
      this.soQuote = {
        id: "",
        date: "",
        prospect: "",
        customer: "",
        name: "",
        contact: "",
        quoted_by: this.getUserId,
        notes: "",
        contact_id: "",
      } as SOQuote;
    },
    formatDate(date: Date) {
      const formattedString = Utils.formatDate(date);
      this.saleOpp.est_close = formattedString;
    },
    formatNumber(number: number) {
      return number.toString();
    },
    setCustomer(customer: Customer) {
      this.saleOpp.cust = customer.cust_id as string;
      this.customerName = customer.name as string;
    },
    pushQuote() {
      if (this.quote.quote) {
        // prevent blank strings from being entered
        if (this.saleOpp.quote_items == null) {
          this.saleOpp.quote_items = [];
        }
        this.saleOpp.quote_items.push(this.quote);
        this.initQuote();
      }
    },
    initQuote() {
      this.quote = {
        quote: "",
      };
    },
    pushSalesOrder() {
      // prevent blank strings from being entered
      if (this.salesOrder.so) {
        if (this.saleOpp.so_items == null) {
          this.saleOpp.so_items = [];
        }
        this.saleOpp.so_items.push(this.salesOrder);
        this.initSalesOrder();
      }
    },
    initSalesOrder() {
      this.salesOrder = {
        so: "",
      };
    },
    async save() {
      this.isFormSubmitted = true;

      this.saleOpp.amount = this.saleOppAmount.toString();
      this.pushQuote();
      this.pushSalesOrder();
      const isCustomerFieldPopulated = await this.v$.customerName.$validate();
      if (isCustomerFieldPopulated) {
        if (this.isQuoteNotEmpty()) {
          this.saleOpp.quote_items.push(this.quote);
          this.resetQuote();
        }
        if (this.isSoNotEmpty()) {
          this.saleOpp.so_items.push(this.salesOrder);
          this.resetSalesOrder();
        }
        this.submitLoading = true;
        if (this.isEditing) {
          // the dialog is called on row click - editing a record
          this.saleOppService
            .updateSaleOpp(this.saleOpp, this.saleOppCopy)
            .then(() => {
              this.submitLoading = false;
              this.$emit("onSave", this.saleOpp);
              const notification = {
                message: "Sale Opportunity updated",
                type: "success",
              };
              this.addNotification(notification);
            })
            .catch(() => {
              this.submitLoading = false;
            });
        } else {
          this.saleOppService
            .postSaleOpp(this.saleOpp)
            .then((response: any) => {
              this.submitLoading = false;
              this.saleOpp.id = response.recordId;
              this.$emit("onSave", this.saleOpp);
              const notification = {
                message: "Sale Opportunity saved",
                type: "success",
              };
              this.addNotification(notification);
            })
            .catch(() => {
              this.submitLoading = false;
            });
        }
        this.isFormSubmitted = false;
      }
    },
    isSoNotEmpty() {
      return this.salesOrder.so != "";
    },
    resetSalesOrder() {
      this.salesOrder = {
        so: "",
      };
    },
    isQuoteNotEmpty() {
      return this.quote.quote != "";
    },
    resetQuote() {
      this.quote = {
        quote: "",
      };
    },
    setProspect(prospect: Prospect) {
      this.prospectName = prospect.name;
      this.saleOpp.prospect = prospect.id;
    },
    initCust() {
      if (this.saleOpp && this.saleOpp.cust_name) {
        this.customerName = this.saleOpp.cust_name;
      }
    },
    initProspect() {
      if (this.saleOpp.prospect) {
        this.prospectService
          .getProspect(this.saleOpp.prospect)
          .then((response) => {
            this.prospectName = (response as Prospect).name as string;
          });
      }
    },
    handleContactSelected(contact: any) {
      this.saleOpp.contact = contact.contact_id;
    },
  },
  watch: {
    show(newShow) {
      this.hidden = newShow;
    },
    saleOpp(newSaleOpp) {
      this.saleOpp = newSaleOpp;
    },
  },
});
