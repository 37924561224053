
import { defineComponent } from "vue";
import Draggable from "vuedraggable";
import Badge from "primevue/badge";
import KanbanItem from "./KanbanItem.vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import { mapGetters } from "vuex";

export default defineComponent({
  components: {
    Draggable,
    KanbanItem,
    LoadingSpinner,
    Badge,
  },
  props: {
    lists: {
      type: Array,
      required: true,
      default: () => [],
    },
    section: String,
    loading: Boolean,
  },
  emits: ["onClickEdit", "onStatusChange"],
  data() {
    return {
      dragging: false,
      orderedLists: {} as any,
    };
  },
  methods: {
    onItemChanged(status: any, event: any) {
      if (event.added) {
        const statusToSet =
          this.section === "quotes" || this.section === "fieldservices"
            ? status.initial
            : status.status;
        this.$emit("onStatusChange", { item: event, status: statusToSet });
      }
    },
    onClickEdit(item: any) {
      this.$emit("onClickEdit", item);
    },
  },
  computed: {
    ...mapGetters({
      getUserId: "session/getUserId",
    }),
  },
});
